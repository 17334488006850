import { Navbar,Nav, NavDropdown, Button,Form, FormControl, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from "react";
import logo from '../../images/logo.png';
import './style.css'; // Tell webpack that Button.js uses these styles


export class NavBar extends Component{
    render(){
        return(
<Navbar className="color-navbar" expand="lg">
  <Container fluid>
    <Navbar.Brand href="#" style={{ color: '#000' }}>
      <img
        src={logo}
        width="50"
        height="26"
        className="d-inline-block align-top"
        alt="React Bootstrap logo"
      />
      &nbsp;
      House Of Drapers</Navbar.Brand>
    <Navbar.Toggle aria-controls="navbarScroll" />
    <Navbar.Collapse id="navbarScroll">
      <Nav
        className="me-auto my-2 my-lg-0"
        style={{ maxHeight: '100%' }}
        navbarScroll>
        
        <NavDropdown className = "black-color" title="Catalog" id="navbarScrollingDropdown">
          <NavDropdown.Item href="#action3"><></>Men</NavDropdown.Item>
          <NavDropdown.Item href="#action4">Women</NavDropdown.Item>
          <NavDropdown.Divider /> 
          <NavDropdown.Item href="#action6">T-shirts</NavDropdown.Item>
          <NavDropdown.Item href="#action6">Shirts</NavDropdown.Item>
          <NavDropdown.Item href="#action5">Jeans</NavDropdown.Item>
          <NavDropdown.Item href="#action6">Trousers</NavDropdown.Item>          
          <NavDropdown.Item href="#action6">Shorts</NavDropdown.Item>          
          <NavDropdown.Divider /> 
          <NavDropdown.Item href="#action6">Perfumes</NavDropdown.Item>
          <NavDropdown.Item href="#action5">Watch</NavDropdown.Item>
          <NavDropdown.Item href="#action6">Sneaker</NavDropdown.Item>
          <NavDropdown.Item href="#action6">Glasses</NavDropdown.Item>
        </NavDropdown>
        <Nav.Link className = "black-color" href="#action2"><text className="black-color">About Us</text></Nav.Link>
        <Nav.Link className = "black-color" href="#action3"><text className="black-color">Contact</text></Nav.Link>
        <Nav.Link className = "black-color" href="#" disabled>
        <text className="black-color">Become a Creator</text>
        </Nav.Link>
      </Nav>
      <Form className="d-flex">
        <FormControl
          type="search"
          placeholder="Search"
          className="me-2"
          aria-label="Search"
        />
        <Button  variant="flat" size="sm">Search</Button>
      </Form>
    </Navbar.Collapse>
  </Container>
</Navbar>
    );
    }
}
