import React from "react";
import {
Box,
Container,
Row,
Column,
FooterLink,
Heading,
} from "./FooterStyles";

import(/*webpackIgnore: true*/ 'https://kit.fontawesome.com/1c787b6d25.js');


const Footer = () => {
return (
	<Box>
	<h1 style={{ color: "black",
				textAlign: "center",
				marginTop: "-50px" }}>
        House Of Drapers
	</h1>
  <br></br>
	<Container>
		<Row>

		<Column>
			<Heading>Shopping</Heading>
			<FooterLink href="#">Men</FooterLink>
			<FooterLink href="#">Women</FooterLink>
			<FooterLink href="#">Kids</FooterLink>
			<FooterLink href="#">Accesories</FooterLink>
		</Column>
		<Column>
			<Heading>Top Designers</Heading>
			<FooterLink href="#">Hot & Trending &#128293;</FooterLink>
			<FooterLink href="#">For Men</FooterLink>
			<FooterLink href="#">For Women</FooterLink>
			<FooterLink href="#">For Accesories</FooterLink>
		</Column>
    <Column>
			<Heading>About Us</Heading>
			<FooterLink href="#">Team</FooterLink>
			<FooterLink href="#">Policy</FooterLink>
			<FooterLink href="#">Become a Creator</FooterLink>
		</Column>
		<Column>
			<Heading>Social Media</Heading>
			<FooterLink href="#">
			<i className="fab fa-facebook-f">
				<span style={{ marginLeft: "10px" }}>
				Facebook
				</span>
			</i>
			</FooterLink>
			<FooterLink href="#">
			<i className="fab fa-instagram">
				<span style={{ marginLeft: "10px" }}>
				Instagram
				</span>
			</i>
			</FooterLink>
			<FooterLink href="#">
			<i className="fab fa-twitter">
				<span style={{ marginLeft: "10px" }}>
				Twitter
				</span>
			</i>
			</FooterLink>
			<FooterLink href="#">
			<i className="fab fa-youtube">
				<span style={{ marginLeft: "10px" }}>
				Youtube
				</span>
			</i>
			</FooterLink>
		</Column>
		</Row>
	</Container>
	</Box>
);
};

export default Footer;
