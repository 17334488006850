import './App.css';
import React, {useEffect} from "react";
import {NavBar} from './components/navbar/nav'
import {ZigZag} from './components/zigzag/zizag'
import {Slider} from './components/slider/Slider'
import {Testimonials} from './components/testimonals/Testimonals'
import {Influncer} from './components/influncer/Influncer'
import Footer from './components/footer/footer'
function App() {
  useEffect(() => {
    document.title = "Home";  
  }, []);

  return (
    <div>
    <NavBar/>
    <ZigZag/>
    <hr/>
    <Slider/>
    <hr/>
    <Testimonials/>
    <hr/>
    <Influncer/>
    <hr/>
    <Footer/>
    </div>
  )
};


export default App;
