import image1 from '../../images/1.jpg';
import image2 from '../../images/2.jpg';
import image3 from '../../images/3.jpg';
import React, { Component } from "react";
import './style.css'; // Tell webpack that Button.js uses these styles
import 'bootstrap/dist/css/bootstrap.min.css';


export class ZigZag extends Component{
    render(){
        return(

        <div>
            <div className="container ">
                <div className="row">
                    <div className="column-33">
                        <img src={image1} alt="Image" className="img-fluid full-img"/>
                    </div>
                    <div className="column-66">
                        <h1 className="xlarge-font"><b>House Of Drapers</b></h1>
                        <h1 className="large-font" style={{color:"MediumSeaGreen"}}><b>What we do?</b></h1>
                        <p>House Of Drapers is a platform that connects budding fashion desingers and customers.
                            ipsum consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                            aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur.
                        </p>
                    </div>
                    
                </div>
            </div>
            <hr></hr>
            <div className="container">
                <div className="row">
                    <div className="column-66">
                        <h1 className="xlarge-font"><b>For the Drapers</b></h1>
                        <h1 className="large-font" style={{color:"MediumSeaGreen"}}><b>Why us?</b></h1>
                        <p>You should buy this app because lorem
                            ipsum consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                            aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur.</p>
                    </div>
                    <div className="column-33">
                        <img src={image2} className="img-fluid full-img" alt="Image"/>
                    </div>
                </div>
            </div>
            <hr></hr>
            <div className="container">
                <div className="row">
                    <div className="column-33">
                        <img src={image3} alt="Image" className="img-fluid full-img"/>
                    </div>
                    <div className="column-66">
                        <h1 className="xlarge-font"><b>For the Dapers</b></h1>
                        <h1 className="large-font" style={{color:"red"}}><b>Why us?</b></h1>
                        <p>  Sharp and clear photos with the
                            world's best photo engine, incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                            quis nostrud exercitation ullamco laboris nisi ut aliquipex ea commodo consequat. Duis aute
                            irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                </div>
            </div>
        </div>
        </div>
        );
    }
}
