import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import './style.css'; // Tell webpack that Button.js uses these styles
import image1 from '../../images/f1.jpg';
import image2 from '../../images/f2.gif';
import image3 from '../../images/f3.jpg';

export class Slider extends Component {
  render() {
    return (
     <div > 
         <br/>
         <h1 style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>Trending Fashion</h1>
         <br/>
     
      <Carousel
        showArrows={false}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={1000}
        swipeable={true}
      >
        
        <div className="slider_container">
          <img src={image1} className="my_img_slider" />
        </div>

        <div className="slider_container">
          <img src={image2}  className="my_img_slider" />
        </div>

        <div className="slider_container">
          <img src={image3}  className="my_img_slider" />
        </div>
      </Carousel>
      </div>   
    );
  }
}