import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import './style.css'; // Tell webpack that Button.js uses these styles
import image1 from '../../images/t1.jpg';
import image2 from '../../images/t2.jpg';
import image3 from '../../images/t3.jpg';

export class Influncer extends Component {
  render() {
    return (
     <div > 
         <br/>
         <h1 style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>Get Inspired by Influncers</h1>
         <br/>
     
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={1000}
        swipeable={true}
      >
        
        <div>
          <img src={image1} className="my_img_influncer" />
          <div className="myCarousel1">
            <h3>Shirley Fultz</h3>
            <h4>Influncer</h4>
            <p>
              It's freeing to be able to catch up on customized news and not be
              distracted by a social media element on the same site
            </p>
          </div>
        </div>

        <div>
          <img src={image2}  className="my_img_influncer" />
          <div className="myCarousel1">
            <h3>Daniel Keystone</h3>
            <h4>Influncer</h4>
            <p>
              The simple and intuitive design makes it easy for me use. I highly
              recommend Fetch to my peers.
            </p>
          </div>
        </div>

        <div>
          <img src={image3}  className="my_img_influncer" />
          <div className="myCarousel1">
            <h3>Theo Sorel</h3>
            <h4>Influncer</h4>
            <p>
              I enjoy catching up with Fetch on my laptop, or on my phone when
              I'm on the go!
            </p>
          </div>
        </div>
      </Carousel>
      </div>   
    );
  }
}